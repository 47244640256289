@import "fonts";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: "Proxima Nova", Helvetica, Arial, sans-serif;
}

header {
  padding: 0;
  font-weight: 800;
}
