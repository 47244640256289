@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Reg-webfont.woff2") format("woff2"),
       url("../fonts/ProximaNova-Reg-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Sbold-webfont.woff2") format("woff2"),
       url("../fonts/ProximaNova-Sbold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Xbold-webfont.woff2") format("woff2"),
       url("../fonts/ProximaNova-Xbold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Condensed";
  src: url("../fonts/ProximaNova-Condensed-Extrabold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
